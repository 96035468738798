import plpQuery from '~layers/app/graphql/plp.gql'
import plpPageQuery from '~layers/app/graphql/plpPage.gql'
import plpThemePageQuery from '~layers/app/graphql/plpThemePage.gql'

import type { StrapiPlpPage } from '~layers/app/types/strapi'
import type { EsProduct } from '~layers/app/types/elastic'

export function useCatalog(meta) {
  const products = useState<EsProduct[]>('products', () => [])

  const data = ref()
  const loading = ref(false)
  const error = ref()

  const { strapiData, getStrapiData } = useStrapiCms<StrapiPlpPage>(
    meta?.value?.strapi_id || '',
    'PUBLISHED',
    (meta?.value?.page_type === 'plp-theme' ? plpThemePageQuery : plpPageQuery),
    '',
  )

  const {
    search,
  } = useCatalogSearch()

  const {
    page,
    sort,
    sortOptions,
    itemsPerPage,
    totalCount,
  } = useCatalogPaginator()

  const {
    rawFilters,
    filtersFromRoute,
  } = useCatalogFilters(meta)

  const variables = computed<any>(() => {
    const sortOption = sortOptions.find(sortOption => sortOption.key === sort.value)

    return {
      // catId: categoryFilter,
      search: search.value,
      page: page.value,
      filters: filtersFromRoute.value,
      itemsPerPage: itemsPerPage.value,
      sortOrder: sortOption?.field,
      sortDirection: sortOption?.direction,
      merchandiserSortKey: meta.value?.merchandiserSortKey,
    }
  })

  async function fetchProductData() {
    loading.value = true

    const response = await useAsyncQuery<any>(plpQuery, variables.value)

    data.value = response.data.value
    error.value = response.error.value

    if (error.value) {
      console.error(error.value)
    }

    if (!data.value) {
      return
    }

    totalCount.value = data?.value?.esProductList?.totalCount || 0
    rawFilters.value = data?.value?.esProductList?.filters || []
    products.value = data?.value?.esProductList?.products || []

    loading.value = false
  }

  watch(variables, fetchProductData)

  return {
    products,
    fetchProductData,
    strapiData,
    getStrapiData,
  }
}
