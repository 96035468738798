export function useCatalogPaginator() {
// Paginator data &  methods
  const view = useLocalStorage<'grid' | 'list'>('viewType', 'grid', { initOnMounted: true })
  function updateView(value: 'grid' | 'list') {
    view.value = value
  }

  const defaultPage = 1
  function updatePage(value: number) {
    const route = useRoute()
    const router = useRouter()

    const query = {
      ...route.query,
      p: value,
    }

    if (value === defaultPage) {
      delete query.p
    }

    router.push({ query })
  }

  const totalCount = useState<number>('totalCount', () => 0)
  const itemsPerPageOptions: number[] = [24, 36, 48, 60, 72]
  const defaultItemsPerPage = itemsPerPageOptions[1]
  function updateItemsPerPage(value: number) {
    const route = useRoute()
    const router = useRouter()

    const query = {
      ...route.query,
      limit: value,
    }

    if (value === defaultItemsPerPage) {
      delete query.limit
    }

    delete query.p // Reset page

    router.push({ query })
  }

  const sortOptions: any[] = [
    { key: 'popular', field: 'DEFAULT', direction: 'DESC', label: 'Recommended' },
    { key: 'priceasc', field: 'PRICE', direction: 'ASC', label: 'Lowest price' },
    { key: 'pricedesc', field: 'PRICE', direction: 'DESC', label: 'Highest price' },
    { key: 'discount', field: 'DISCOUNT', direction: 'DESC', label: 'Sale' },
    { key: 'most_sold', field: 'MOST_SOLD', direction: 'DESC', label: 'Most sold' },
    { key: 'new', field: 'NEW', direction: 'DESC', label: 'New' },
  ]
  const defaultSort = sortOptions.find(sort => sort.field === 'DEFAULT')?.key || sortOptions[0]?.key
  function updateSort(value: string) {
    const route = useRoute()
    const router = useRouter()

    const query = {
      ...route.query,
      sort: value,
    }

    if (value === defaultSort) {
      delete query.sort
    }

    delete query.p // Reset page

    router.push({ query })
  }

  const paginatorMethods = {
    updateView,
    updateItemsPerPage,
    updateSort,
    updatePage,
  }

  const route = useRoute()

  const page = computed<number>(() => {
    return parseInt(route.query.p || defaultPage)
  })

  const itemsPerPage = computed<number>(() => {
    return parseInt(route.query.limit || defaultItemsPerPage)
  })

  const sort = computed<string>(() => {
    return route.query.sort || defaultSort
  })

  const totalPages = computed<number>(() => {
    return Math.ceil(totalCount.value / itemsPerPage.value)
  })

  return {
    page,
    defaultPage,
    updatePage,

    sort,
    sortOptions,
    defaultSort,
    updateSort,

    itemsPerPage,
    itemsPerPageOptions,
    defaultItemsPerPage,
    updateItemsPerPage,

    view,
    updateView,

    totalPages,
    totalCount,

    paginatorMethods,
  }
}
